import React, { useEffect } from "react";
import "./SampleInfo.scss";

import { apiTranslate, translate } from "services/translate";
import { Divider } from "@mui/material";
import CPCard from "components/shared/cp-card/CPCard";
import GlobalProfileServices from "services/profile";

function SampleInfo(props: any) {
  const [sampleData, setSampleData] = React.useState<any>();
  const [columns, setColumns] = React.useState<any>([]);
  const [properties, setProperties] = React.useState<any>([]);
  const [sampleInfo, setSampleInfo] = React.useState<any>([]);

  useEffect(() => {
    setSampleData(props.sample);
  }, [props.sample]);

  const getSampleData = function () {
    let tempArray: any = [];

    properties.map((property: any, index: any) => {
      switch (property.type) {
        case "MULTILINETEXT":
        case "DROPDOWN":
        case "ONELINETEXT":
          sampleData.sampleProperties.map((sampleProperty: any) => {
            if (
              sampleProperty.property.limsNumber === property.labDBIndicator
            ) {
              tempArray[index] = {
                label: property.label,
                type: "string",
                value: sampleProperty.processedValue,
              };
            }
          });
          break;
        case "Date":
          console.log("Column Type: ", property.type);
          break;
        default:
          console.log("Column Type: ", property.type);
          break;
      }
      setSampleInfo(tempArray);
      //   console.log("After Column Loop: ", sampleInfo);
    });
  };

  useEffect(() => {
    GlobalProfileServices.getProfileData().then((res: any) => {
      setColumns([...res?.tableColumns?.SAMPLE]);
    });
  }, [sampleData]);

  useEffect(() => {
    if (columns?.length) {
      let tempColumn: any = [];
      columns.map((column: any, columnIndex: any) => {
        if (column.visible) {
          tempColumn.push({
            id: column.id,
            label: column.property?.name
              ? apiTranslate(
                  column.property?.name,
                  column.property?.propertyTranslations
                )
              : translate("db_sample_" + column.objectPath) || null,
            type: column.property?.type || null,
            labDBIndicator: column.property?.limsNumber || null,
            objectPath: column.objectPath || null,
            align: "left",
          });

          if (columns.length - 1 === columnIndex) {
            setProperties(tempColumn);
            if (properties.length > 1) getSampleData();
          }
        } else if (columns.length - 1 === columnIndex) {
          setProperties(tempColumn);
          if (properties.length > 1) getSampleData();
        }
      });

      //   console.log("Full Columns: ", tempColumn);
    }
  }, [columns]);

  return (
    <CPCard className="cp-sample-info">
      <div className="cp-sample-info-header">
        <div className="cp-sample-number">
          <p className="cp-sample-number-label">{translate("Sample Number")}</p>
          <h2 className="cp-sample-number-title">
            {sampleData?.sampleNumber || sampleData?.id}
          </h2>
        </div>
        {/* <Link className="cp-sample-reuse-registration" href="">
          {translate("ReuseForSampleRegistration")}
        </Link> */}
      </div>

      {/* <CPStepper steps={steps} className="cp-sample-slider" /> */}
      {sampleInfo?.map((info: any) => {
        return (
          <>
            <Divider
              sx={{
                mt: 2,
                mb: 2,
              }}
            />

            <div className="cp-sample-info-table">
              <div className="cp-sample-info-table-label">{info.label}</div>
              <div className="cp-sample-info-table-data">{info.value}</div>
            </div>
          </>
        );
      })}

      {/* <Divider
        sx={{
          mt: 2,
          mb: 2,
        }}
      />
      <div className="cp-sample-info-table">
        <div className="cp-sample-info-table-label">Status</div>
        <div className="cp-sample-info-table-data  ">
          <span className="cp-status cp-blue-status"> Booked In</span>
        </div>
      </div> */}

      {/* <Divider
        sx={{
          mt: 2,
          mb: 2,
        }}
      />
      <div className="cp-sample-info-table">
        <div className="cp-sample-info-table-label">
          {translate("Documents")}
        </div>
        <div className="cp-sample-info-table-data">
          {[
            { type: "pdf", link: "pdf.pdf", name: "Information.pdf" },
            { type: "doc", link: "doc.doc", name: "Musterbericht.doc" },
          ].map((file: any, index: any) => {
            let icon;
            if (file.type === "pdf") {
              icon = <PdfFile />;
            } else if (file.type === "doc") {
              icon = <DocFile />;
            } else if (file.type === "xlsx") {
              icon = <XlsxFile />;
            }
            let link = (
              <Link target="_blank" rel="noopener" href={file.link} key={index}>
                {icon} {file.name}
              </Link>
            );
            return link;
          })}
        </div>
      </div> */}
    </CPCard>
  );
}

export default SampleInfo;
