const LocalStorage = {
  setToken(token: string) {
    localStorage.setItem("token", token);
  },
  getToken() {
    // if property of Token exists return token. Otherwise return false
    return localStorage.getItem("token")
      ? localStorage.getItem("token")
      : false;
  },
  removeToken() {
    localStorage.clear();
    window.location.href = "/login";
  },
};

export { LocalStorage };
