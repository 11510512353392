import "./CPInfoBox.scss";

function CPInfoBox(props: any) {
  return (
    <section className={"cp-infobox " + props.className}>
      <div className="cp-infobox-icon">{props.icon}</div>
      <div className="cp-infobox-text">{props.title}</div>
      <div className="cp-infobox-desc">{props.desc}</div>
    </section>
  );
}

export default CPInfoBox;
