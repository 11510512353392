import React from "react";
import { TextField, InputLabel, FormControl } from "@mui/material";
import "./InputPassword.scss";
import validator from "validator";
import { translate } from "services/translate";

function InputPassword(props: any) {
  const [state, setState] = React.useState({
    [props.id || "value"]: "",
    isValid: false,
    isTuched: false,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var val = event.target.value;

    let newState = {
      [props.id || "value"]: val,
      isValid: props.required
        ? validator.isStrongPassword(val, {
            minLength: 13,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        : true,
      //   isValid: true,
      isTuched: true,
    };
    setState(newState);
    props.getValue(newState);
  };

  React.useEffect(() => {
    setState({ ...props.value });
  }, [props]);

  return (
    <FormControl className={"cp-password-input " + props.className}>
      <InputLabel htmlFor="component-helper">
        {props.label + (props.required ? "*" : "") || "name"}
      </InputLabel>
      <TextField
        error={state.isTuched && !state.isValid}
        required={props.required ? true : false}
        placeholder={props.placeholder || props.label}
        id={props.id ? props.id : "input-secret-id"}
        type="password"
        variant="outlined"
        value={state[props.id || "value"]}
        onKeyPress={props.onKeyPress || null}
        //value={state[props.id]}
        autoComplete={props.autoComplete || "new-password" + Math.random()}
        // inputProps={{
        //   autocomplete: "new-password",
        //   form: {
        //     autocomplete: "off",
        //   },
        // }}
        helperText={
          state.isTuched && !state.isValid
            ? translate(props.errMessage || "passwordNotValid")
            : ""
        }
        onChange={handleChange}
      />
    </FormControl>
  );
}

export default InputPassword;
