import { ReactComponent as IsLoadingIcon } from "assets/images/LoadingTentamus.svg";
import "./CPLoadingScreen.scss";

type LoadingScreenType = {
  className?: string;
};

const CPLoadingScreen = (props: LoadingScreenType) => {
  return (
    <IsLoadingIcon
      className={
        props.className ? "cp-loading " + props.className : "cp-loading"
      }
    />
  );
};

export default CPLoadingScreen;
