import React from "react";
import { FormControl, Checkbox, FormControlLabel } from "@mui/material";
import "./Checkbox.scss";

function CPCheckbox(props: any) {
  const [state, setState] = React.useState({
    [props.id || "value"]: false,
    isTuched: false,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var val = event.target.checked;
    setState({
      [props.id || "value"]: val,
      isTuched: true,
    });
    props.getValue({
      [props.id || "value"]: val,
      isTuched: true,
    });
  };

  React.useEffect(() => {}, [state]);

  return (
    <FormControl className={"checkbox " + props.className}>
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={props.value?.value ? true : false}
            onChange={handleChange}
          />
        }
        label={props.placeholder || props.label}
      />
    </FormControl>
  );
}

export default CPCheckbox;
