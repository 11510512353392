import React, { useEffect, useState } from "react";
import "./ResultDetails.scss";

import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/arrow-left.svg";
import { translate } from "../../../services/translate";
import { Link, useLocation } from "react-router-dom";
import CPCard from "../../../components/shared/cp-card/CPCard";
import SampleInfo from "./SampleInfo/SampleInfo";
import SampleResults from "./SampleResults/SampleResults";
import CPAuthorityGuard from "layouts/AuthorityGuard/AuthorityGuard";
import GlobalProfileServices from "services/profile";

function ResultDetails() {
  const [sampleData, setSampleData] = React.useState<any>();
  const [profileIsChanged, setProfileIsChanged] = useState(true);

  const [profile, setProfile] = useState<any>();

  const location = useLocation();

  useEffect(() => {
    setSampleData(location?.state);
    console.log("Sample Details", location);
  }, [location]);

  useEffect(() => {
    if (profileIsChanged) {
      GlobalProfileServices.getProfileData().then((res: any) => {
        setProfile({ ...res });
        setProfileIsChanged(false);
      });
    }
  }, [profileIsChanged]);
  useEffect(() => {
    // console.log("Profile In Samples: ", profile);
  }, [profile]);
  return (
    <CPAuthorityGuard roles={profile?.roles} page="RESULT" showMessage>
      <div className="cp-sample-details">
        <Link to="/samples" className="cp-sample-detail-back-link">
          <ArrowLeftIcon /> {translate("Back to Sample List")}
        </Link>

        <SampleInfo sample={sampleData} />

        <CPCard className="cp-sample-detail">
          <h3 className="cp-sample-samples-title">{translate("Results")}</h3>
          <SampleResults analyses={sampleData?.analyses} />
        </CPCard>
      </div>
    </CPAuthorityGuard>
  );
}

export default ResultDetails;
