import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./login.scss";

import { Box, Container, Link } from "@mui/material";

//Components
import CPCheckbox from "../../components/shared/chekbox/Checkbox";

import InputEmail from "../../components/shared/input-email/InputEmail";
import InputPassword from "../../components/shared/input-password/InputPassword";
import DefaultButton from "../../components/shared/default-button/DefaultButton";

// Services
import { LoginHttpServices } from "../../services/login";
import { translate } from "services/translate";

interface Location {
  state: {
    from: {
      pathname: string;
    };
  };
}
function Login() {
  let navigate = useNavigate();
  const [form, setForm] = useState({
    password: {
      password: "",
      isValid: false,
      isTuched: false,
    },
    email: { email: "", isValid: false, isTuched: false },
    remember: { value: "", isTuched: false },
  });
  const [formIsValid, setFormIsValid] = React.useState(false);
  const [formIsSubmitted, setFormIsSubmitted] = React.useState(false);
  const [authIsValid, setAuthIsValid] = React.useState(true);

  const location = useLocation() as Location;

  // Submit Form and get tokenS
  const submit = async function () {
    // First remove previous failed login messages
    //   then check if login button is touched without any entry
    //setAuthIsValid(false);
    if (!form.email.isTuched || !form.email.isTuched) {
      let newForm = { ...form };
      newForm.email.isTuched = true;
      newForm.password.isTuched = true;
      setForm(newForm);
      return;
    }

    // stop if form inputs are not valid
    if (!formIsValid) return;

    // Start showing loading and disable button
    setFormIsSubmitted(true);

    const isLogin = await LoginHttpServices.login({
      username: form.email.email,
      password: form.password.password,
    }).then((res) => {
      if (res === true) {
        // GlobalProfileServices.getProfileData(true).then(() => {
        setFormIsSubmitted(false);
        navigate(location.state?.from?.pathname || "/dashboard", {
          replace: true,
        });
        // });
      } else {
        setFormIsSubmitted(false);
        setAuthIsValid(false);
      }
    });
  };

  React.useEffect(() => {
    if (
      (form.email.isTuched && !form.email.isValid) ||
      (form.password.isTuched && !form.password.isValid)
    ) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }
  }, [form]);
  return (
    <div className="login-container">
      <Container className="login-content login-flex" component="section">
        <div className="welcome-container">
          <img src="images/Logo.png" alt="" />
          <h1 className="welcome-text">{translate("welcomeBack")}</h1>
          <p className="welcome-desc">{translate("enterYourDetails")}</p>
        </div>
        <Box
          className="login-form"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={submit}
        >
          <InputEmail
            className="flex-item email-input"
            label={translate("emailInputLabel")}
            required={true}
            id="email"
            value={form.email}
            getValue={(email: any) => setForm({ ...form, email: email })}
            onKeyUp={(e: any) => {
              if (e.key === "Enter" && formIsValid) {
                submit();
              }
            }}
          />

          <InputPassword
            className="flex-item password-input"
            required={true}
            label={translate("passwordLabel")}
            id="password"
            autocomplete="login-password"
            value={form.password}
            getValue={(name: any) => setForm({ ...form, password: name })}
            onKeyPress={(e: any) => {
              if (e.key === "Enter" && formIsValid) {
                submit();
              }
            }}
          />

          <div className="flex-item login-options">
            <CPCheckbox
              className="remember-checkbox"
              label={translate("rememberMe")}
              required={true}
              id="remember"
              value={form.remember}
              getValue={(remember: any) =>
                setForm({ ...form, remember: remember })
              }
            />
            <Link
              className="forget-pass-link"
              href=""
              underline="none"
              color="#130F26"
              onClick={() => {
                navigate("/forget-password");
              }}
            >
              {translate("forgetPassword")}
            </Link>
          </div>

          <div className="cp-login-btn">
            <DefaultButton
              label={translate("login")}
              onClick={submit}
              disabled={!formIsValid}
              isLoading={formIsSubmitted}
            ></DefaultButton>
            {!authIsValid && (
              <p className="cp-auth-failed">
                {translate("userOrPassIncorrect")}
              </p>
            )}
          </div>

          <p className="email-us">
            {translate("dontHaveAccount")}
            <Link
              className="login-link"
              href="#"
              underline="none"
              color="#130F26"
            >
              {translate("emailUs")}
            </Link>
          </p>
        </Box>
        <span className="tentamus-copyright">
          © Tentamus {new Date().getFullYear()}
        </span>
      </Container>

      <section className="login-flex login-image">
        <img src="images/login-img.png" alt="Tentamus"></img>
      </section>
    </div>
  );
}

export default Login;
