import React, { useEffect } from "react";
import "./SampleResults.scss";

import { ReactComponent as SampleIcon } from "assets/images/svg/document.svg";
import CPTable from "components/shared/cp-table/CPTable";
import { apiTranslate, translate } from "services/translate";
import GlobalProfileServices from "services/profile";

// interface Column {
//   id: string;
//   label: string;
//   minWidth?: number;
//   align?: "inherit" | "left" | "center" | "right" | "justify";
//   //format?: (value: number) => string;
// }

function SampleResults(props: any) {
  const [columns, setColumns] = React.useState<any>([]);
  const [rows, setRows] = React.useState<any>([]);

  const [results, setResults] = React.useState<any>([]);

  useEffect(() => {
    setResults(props?.analyses);
    console.log("Results", results);
  }, [props.analyses]);

  // Generate Desired Data for Table
  const generateRows = function () {
    console.log("Data Overview: ", columns, results);
    let tempResults: any = [];

    results?.map(async (result: any, resultIndex: any) => {
      let tempArray: any = [];
      columns.map((column: any, columnIndex: any) => {
        // console.log("Generate Rows " + columnIndex, column, result);
        switch (column.type) {
          case "MULTILINETEXT":
          case "DROPDOWN":
          case "ONELINETEXT":
            tempArray.push({
              label: column.label,
              type: "string",
              value: "",
            });
            // results.map((test: any) => {
            //   if (test.property?.limsNumber === column.labDBIndicator) {
            //     tempArray[columnIndex] = {
            //       label: column.label,
            //       type: "string",
            //       value: test.processedValue,
            //     };
            //   }
            // });
            break;
          case "Date":
            console.log("Column  Type: ", column.type);
            break;
          default:
            // Check if the objectPath is like this test.name
            // So Generate the path to inner object
            let splitObjectPath = column.objectPath.split(".");
            if (splitObjectPath.length > 1) {
              tempArray.push({
                label: column.label,
                type: "string",
                value: result[splitObjectPath[0]][splitObjectPath.pop()],
              });
            } else {
              tempArray.push({
                label: column.label,
                type: "string",
                value: result[column.objectPath],
              });
            }
            break;
        }
        if (columns.length - 1 === columnIndex) {
          tempResults.push(tempArray);
          console.log("After Column Loop: ", tempArray);
        }

        //   setSampleInfo(tempArray);
        //   console.log("After Column Loop: ", sampleInfo);
      });
      if (results.length - 1 === resultIndex) {
        setRows([...tempResults]);
        console.log("ROOOOWS: ", tempResults);

        // console.log("Final In sample list: ", tempSampleData, isLoading);
      }
    });
  };

  // Recieve and set columns for Results
  useEffect(() => {
    GlobalProfileServices.getProfileData().then((res: any) => {
      if (res?.tableColumns?.SAMPLETEST.length) {
        let tempColumn: any = [];
        res.tableColumns.SAMPLETEST.map((column: any, columnIndex: any) => {
          if (column.visible) {
            // console.log("Column Generate: ", column);
            tempColumn.push({
              id: column.id,
              label: column.property?.name
                ? apiTranslate(
                    column.property?.name,
                    column.property?.propertyTranslations
                  )
                : translate("db_sample_" + column.objectPath) || null,
              type: column.property?.type || null,
              labDBIndicator: column?.property?.limsNumber || null,
              objectPath: column.objectPath || null,
              align: "left",
            });

            if (res.tableColumns.SAMPLETEST.length - 1 === columnIndex) {
              setColumns(tempColumn);

              if (columns.length > 0) generateRows();
            }
          } else if (res.tableColumns.SAMPLETEST.length - 1 === columnIndex) {
            setColumns(tempColumn);
            if (columns.length > 0) generateRows();
          }
        });
      }
    });
  }, [results]);

  return (
    <div className="cp-sample-results-table">
      <CPTable
        columns={columns}
        rows={rows}
        disablePagination
        isLoading={false}
        noDataInfo={{
          title: <span>You don’t have any Results yet</span>,
          icon: <SampleIcon />,
          desc: <span>Any Result generated in projects will live here.</span>,
        }}
      ></CPTable>
    </div>
  );
}

export default SampleResults;
