import moment from "moment-timezone";

import "moment/locale/de";
import "moment/locale/fr";

var lg = JSON.parse(localStorage.getItem("profile") || "{}")?.language || "en";
if (!JSON.parse(localStorage.getItem("profile") || "{}")?.language) {
  lg = navigator.language.split("-")[0] || "en";
  if (lg !== "en" && lg !== "de" && lg !== "fr") lg = "en";
}

var globalCurrency = lg === "de" || lg === "fr" ? "EUR" : "USD";
var gussLocalTimezone = moment.tz.guess();

moment.locale(lg);

const Utils = {
  delayDebounceFn: setTimeout(() => {}),
  debounce: (fn: any, ms = 300) => {
    clearTimeout(Utils.delayDebounceFn);

    Utils.delayDebounceFn = setTimeout(() => {
      fn();
      //   navigate(`/search?query=${data}`);
    }, 450);
  },
  CPMoment: require("moment"),
  CPMomentLocalTimezone: (date: string) => {
    return moment(date).tz(gussLocalTimezone).format("L");
  },
  //   CPFormatter: new Intl.NumberFormat(lg, {
  //     style: "currency",
  //     currency: lg === "de" || lg === "fr" ? "EUR" : "USD",
  //   }),
  CPFormatter: (data: any, currency: string = globalCurrency) => {
    return Intl.NumberFormat(lg, {
      style: "currency",
      currency: currency,
      currencyDisplay: "code",
    })
      .format(data)
      .replace(currency, "");
  },
  isEqual: function (obj1: any, obj2: any) {
    var props1 = Object.getOwnPropertyNames(obj1);
    var props2 = Object.getOwnPropertyNames(obj2);
    if (props1.length !== props2.length) {
      return false;
    }
    for (var i = 0; i < props1.length; i++) {
      let val1 = obj1[props1[i]];
      let val2 = obj2[props1[i]];
      let isObjects = this.isObject(val1) && this.isObject(val2);
      if (
        (isObjects && !this.isEqual(val1, val2)) ||
        (!isObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  },
  isObject: function (object: any) {
    return object != null && typeof object === "object";
  },

  clearEmptyobjects: function (obj: any) {
    // console.log("Clear Option", obj);
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    // console.log("Clear Option after", obj);

    return obj;
  },
};

export default Utils;

export const routeHistory = {
  history: {
    prev: "",
    cur: "",
  },
};
