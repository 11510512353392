import React, { ReactElement } from "react";
import "./CPTooltip.scss";
import Tooltip from "@mui/material/Tooltip";

type tooltip = {
  className?: string;
  title: string | number;
  disableInteractive?: boolean;
  children: ReactElement;
};

function CPTooltip(props: tooltip) {
  return (
    <Tooltip
      className={"cp-tooltip " + props.className || ""}
      title={props.title}
      arrow
      disableInteractive={props.disableInteractive || true}
    >
      <span>{props.children}</span>
    </Tooltip>
  );
}

export default CPTooltip;
