import {
  Navigate,
  useLocation,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import { Container } from "@mui/material";

// import Header from "./layouts/Header/Header";
// import Footer from "./layouts/Footer/Footer";

import PageLayout from "./pages/PageLayout";
// import Information from "./pages/information/Information";
import Login from "./pages/login/login";

import { lazy } from "react";
import ForgetPassword from "pages/ForgetPassword/ForgetPassword";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import ResultDetails from "pages/Results/ResultDetails/ResultDetails";
import SignUp from "pages/SignUp/SignUp";
import ActivateAccount from "pages/ActivateAccount/ActivateAccount";
import Page404 from "pages/404/404";

const Dashboard: any = lazy(() => import("./pages/Dashboard/Dashboard"));
const QuotePage: any = lazy(() => import("./pages/Quotes/QuotePage"));
const SampleSubmission: any = lazy(
  () => import("./pages/SampleSumission/SampleSubmission")
);
const Documents: any = lazy(() => import("./pages/Documents/Documents"));
const ResultsPage: any = lazy(() => import("./pages/Results/Results"));
const Profile: any = lazy(() => import("./pages/Profile/Profile"));
const InvoicesPage: any = lazy(() => import("./pages/Invoices/InvoicePage"));
const OrdersPage: any = lazy(() => import("./pages/Orders/OrdersPage"));
const OrdersDetailes: any = lazy(
  () => import("./pages/Orders/OrderDetails/OrderDetails")
);
const SamplesPage: any = lazy(() => import("./pages/Samples/SamplesPage"));
const SampleDetailes: any = lazy(
  () => import("./pages/Samples/SampleDetails/SampleDetails")
);
const StartStep: any = lazy(
  () => import("./pages/SampleSumission/StartStep/StartStep")
);
const TrendingPage: any = lazy(() => import("./pages/Trending/TrendingPage"));
const Search: any = lazy(() => import("./pages/Search"));

function App() {
  //   let [isBlocked, setIsBlocked] = useState(true);
  // //   let blocker = useBlocker(isBlocked);

  //   // Reset the blocker if the user cleans the form
  //   useEffect(() => {
  //     if (blocker.state === "blocked" && !isBlocked) {
  //       blocker.reset();
  //     }
  //   }, [blocker, isBlocked]);

  const router = createBrowserRouter([
    {
      path: "*",
      element: <Page404 />,
    },
    {
      path: "/",
      element: (
        <RequireAuth>
          <PageLayout />
        </RequireAuth>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "quotes",
          element: <QuotePage />,
        },
        {
          path: "invoices",
          element: <InvoicesPage />,
        },
        {
          path: "orders",
          element: <OrdersPage />,
        },
        {
          path: "orders/:orderID",
          element: <OrdersDetailes />,
        },
        {
          path: "samples",
          element: <SamplesPage />,
        },
        {
          path: "samples/:sampleID",
          element: <SampleDetailes />,
        },
        {
          path: "results",
          element: <ResultsPage />,
        },
        {
          path: "results/:id",
          element: <ResultDetails />,
        },
        {
          path: "sample-reg",
          element: <SampleSubmission />,
        },
        {
          path: "sample-reg/start-step",
          element: <StartStep />,
        },
        {
          path: "documents",
          element: <Documents />,
        },
        {
          path: "trending",
          element: <TrendingPage />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "*",
          element: <ForgetPassword />,
        },
        {
          path: "search/:query",
          element: <Search />,
        },
        {
          path: "search",
          element: <Search />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/forget-password",
      element: <ForgetPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/activation",
      element: <ActivateAccount />,
    },
  ]);

  return (
    <div className="container">
      <RouterProvider router={router} />
      {/* {blocker ? <ConfirmNavigation blocker={blocker} /> : null} */}
    </div>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = localStorage.getItem("token") ? true : false;
  let location = useLocation();

  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

// function ConfirmNavigation({ blocker }: { blocker: any }) {
//   if (blocker.state === "blocked") {
//     return (
//       <>
//         <p style={{ color: "red" }}>
//           Blocked the last navigation to {blocker.location.pathname}
//         </p>
//         <button onClick={() => blocker.proceed?.()}>Let me through</button>
//         <button onClick={() => blocker.reset?.()}>Keep me here</button>
//       </>
//     );
//   }

//   if (blocker.state === "proceeding") {
//     return (
//       <p style={{ color: "orange" }}>Proceeding through blocked navigation</p>
//     );
//   }

//   return <p style={{ color: "green" }}>Blocker is currently unblocked</p>;
// }

export default App;
