import useAuthority from "services/useAuthority";
import CPAccessDenied from "components/shared/cp-access-denied/CPAccessDenied";
type AuthorityGuardProps = {
  roles?: {
    id: number;
    name: string;
    description: string;
  }[];
  page: string | string[];
  children: JSX.Element;
  showMessage?: boolean;
};

const CPAuthorityGuard = ({
  roles,
  page,
  children,
  showMessage,
}: AuthorityGuardProps) => {
  const { checkAuthority } = useAuthority();
  const roleMatched = checkAuthority({ roles: roles, page: page });

  return (
    <>
      {!!roleMatched || page === "DASHBOARD" ? (
        children
      ) : roleMatched === false && !!showMessage ? (
        <CPAccessDenied />
      ) : null}
    </>
  );
};

export default CPAuthorityGuard;
