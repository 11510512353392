import { httpServices } from "services/http";

const DownloadServices = {
  getDocument: function (params: any, print = false) {
    return httpServices
      .getDoc("/document/download", params)
      .then((res: any) => {
        console.log("Print boolean", print);
        if (print) {
          var iframe: any = document.getElementsByTagName("iframe");
          var blob = new Blob([res.data], { type: "application/pdf" });
          var blobURL = URL.createObjectURL(blob);

          let url: string = blobURL;
          if (iframe.length < 1) {
            iframe = document.createElement("iframe");

            document.body.appendChild(iframe);
            iframe.src = url;

            iframe.style.display = "none";
            iframe.onload = function () {
              setTimeout(function () {
                iframe.focus();
                iframe.contentWindow.print();
              }, 1);
            };
          } else {
            iframe[0].src = blobURL;
          }
        } else {
          //   console.log("Document: ", res);
          let objectUrl = window.URL.createObjectURL(res.data);
          let anchor = document.createElement("a");
          anchor.setAttribute("class", "cp-generated-link");
          document.body.appendChild(anchor);
          anchor.href = objectUrl;
          anchor.download = params.name || "some-file.pdf";
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
          setTimeout(() => {
            let elements: any =
              document.getElementsByClassName("cp-generated-link");
            for (var i = 0; i < elements.length; i++) {
              elements[i].parentNode.removeChild(elements[i]);
            }
          }, 1000);
        }
        return true;
      });
  },
};

export default DownloadServices;
