import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./ForgetPassword.scss";

import { Box, Container, Link } from "@mui/material";

//Components

import InputEmail from "../../components/shared/input-email/InputEmail";
import DefaultButton from "../../components/shared/default-button/DefaultButton";

// Services
import { LoginHttpServices } from "../../services/login";
import { translate } from "services/translate";
import CPToast from "components/shared/cp-toast/CPToast";

interface Location {
  state: {
    from: {
      pathname: string;
    };
  };
}
function ForgetPassword() {
  let navigate = useNavigate();
  const [form, setForm] = useState({
    email: { email: "", isValid: false, isTuched: false },
  });
  const [formIsValid, setFormIsValid] = React.useState(false);
  const [formIsSubmitted, setFormIsSubmitted] = React.useState(false);

  const [toast, setToast] = React.useState({
    open: false,
    type: "success",
    message: translate("success"),
  });

  const location = useLocation() as Location;

  // Submit Form and get tokenS
  const submit = async function () {
    // First remove previous failed login messages
    //   then check if login button is touched without any entry
    //setAuthIsValid(false);
    if (!form.email.isTuched || !form.email.isTuched) {
      let newForm = { ...form };
      newForm.email.isTuched = true;
      setForm(newForm);
      return;
    }

    // stop if form inputs are not valid
    if (!formIsValid) return;

    // Start showing loading and disable button
    setFormIsSubmitted(true);

    const isLogin = await LoginHttpServices.forgetPassword({
      email: form.email.email,
    }).then((res) => {
      if (res.data?.hasError) {
        setFormIsSubmitted(false);
        setToast({
          ...{
            open: true,
            type: "error",
            message: translate("invalidEmail"),
          },
        });
      } else {
        setFormIsSubmitted(false);
        setToast({
          ...{
            open: true,
            type: "success",
            message: translate("resetPasswordTokenSent"),
          },
        });
        setTimeout(() => {
          navigate("/reset-password");
        }, 4000);
      }
    });
  };

  React.useEffect(() => {
    if (form.email.isTuched && !form.email.isValid) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }
  }, [form]);
  return (
    <div className="cp-forget-password-container">
      <Container
        className="cp-forget-password-content cp-forget-password-flex"
        component="section"
      >
        <div className="welcome-container">
          <img src={window.location.origin + "/images/Logo.png"} alt="" />
          <h1 className="welcome-text">{translate("forgetPassword")}</h1>
          <p className="welcome-desc">{translate("enterYourEmail")}</p>
        </div>
        <Box
          className="cp-forget-password-form"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={submit}
        >
          <InputEmail
            className="flex-item email-input"
            label={translate("emailInputLabel")}
            required={true}
            id="email"
            value={form.email}
            getValue={(email: any) => setForm({ ...form, email: email })}
          />

          <div className="flex-item cp-forget-password-options">
            <Link
              className="forget-pass-link"
              href="/login"
              underline="none"
              color="#130F26"
            >
              {translate("backToLogin")}
            </Link>
          </div>

          <div className="cp-forget-password-btn">
            <DefaultButton
              label={translate("forgetPasswordBtn")}
              onClick={submit}
              disabled={!formIsValid}
              isLoading={formIsSubmitted}
            ></DefaultButton>
            {/* {!authIsValid && (
              <p className="cp-auth-failed">
                Username or password is incorrect
              </p>
            )} */}
          </div>

          {/* <p className="email-us">
            Don’t have an account?
            <Link
              className="cp-forget-password-link"
              href="#"
              underline="none"
              color="#130F26"
            >
              Email Us
            </Link>
          </p> */}
        </Box>
        <span className="tentamus-copyright">
          © Tentamus {new Date().getFullYear()}
        </span>
      </Container>

      <section className="cp-forget-password-flex cp-forget-password-image">
        <img
          src={window.location.origin + "/images/login-img.png"}
          alt=""
        ></img>
      </section>
      <CPToast
        open={toast.open}
        type={toast.type}
        message={toast.message}
        getValue={(value: any) =>
          setToast({
            ...{
              open: false,
              type: "",
              message: "",
            },
          })
        }
      />
    </div>
  );
}

export default ForgetPassword;
