import { httpServices } from "services/http";

const GlobalProfileServices = {
  timer: null as any,
  profile: null as any,
  getProfileDetails: function (params = null) {
    return httpServices.getMethod("user/details", params).then((res: any) => {
      let groupBy: any = {};
      res.data.tableColumns.map((item: any) => {
        if (groupBy[item.tableEnum]) {
          groupBy[item.tableEnum].push(item);
        } else {
          groupBy[item.tableEnum] = [];
          groupBy[item.tableEnum].push(item);
        }
      });
      for (const item in groupBy) {
        // console.log("Loopeeeeed: ", item);
        groupBy[item] = groupBy[item].sort(
          (a: any, b: any) => a.sortingOrder - b.sortingOrder
        );
      }
      res.data.tableColumns = groupBy;
      // .sort(
      //   (a: any, b: any) => a.sortingOrder - b.sortingOrder
      // );
      res.data.language = res.data.language.toLowerCase() || "en";
      localStorage.setItem("profile", JSON.stringify(res.data));
      return res.data;
    });
  },
  getProfileData: async function (force = false) {
    // clearTimeout(this.timer);
    // this.timer = setTimeout(async () => {
    if (
      (this.profile === null && localStorage.getItem("profile") === null) ||
      force === true
    ) {
      this.profile = await this.getProfileDetails();
      //   console.log("Profile Called API:", this.profile);
      return this.profile;
    } else if (
      this.profile === null &&
      localStorage.getItem("profile") !== null
    ) {
      this.profile = JSON.parse(localStorage.getItem("profile") || "");
      //   console.log("Profile in LocalStorage:", this.profile);
      return this.profile;
    } else {
      //   console.log("Profile in Cache:", this.profile);
      return this.profile;
    }
    // }, 20);
  },
};

export default GlobalProfileServices;
