import axios from "axios";
import config from "./config";
import { LocalStorage } from "./LocalStorage";

const axiosInstance = axios.create({
  baseURL: config.getBaseUrl(),
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (request) => {
    request.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return request;
  },
  (error) => {
    // throw new Error(error);
    return error;
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log("APi Error", error.response);

    return error.response;
  }
);

const services = {
  getMethod(url: string, params?: any) {
    return axiosInstance.get(url, {
      ...params,
    });
  },
  postMethod(url: string, params?: any) {
    return axiosInstance.post(url, {
      ...params,
    });
  },
};

const LoginHttpServices = {
  login: async function (params: any) {
    localStorage.clear();
    return await services
      .postMethod("/token/generate-token", params)
      .then((res: any) => {
        if (res.status === 401) {
          return false;
        } else {
          LocalStorage.setToken(res.data.token);
          return true;
        }
      })
      .catch((err) => {
        return false;
      });
  },
  forgetPassword: async function (params: any) {
    localStorage.clear();
    return await services.postMethod("/user/forgetpass", params);
  },
  resetPassword: async function (params: any) {
    localStorage.clear();
    return await services.postMethod("/user/recoverpass", params);
    //   .then((res: any) => {
    //     if (res === 401) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Error: ", err);
    //   });
  },
  activateAccount: async function (activationToken: any) {
    localStorage.clear();
    return await services.getMethod(
      "/user/activation?activationToken=" + activationToken
    );
    //   .then((res: any) => {
    //     if (res === 401) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Error: ", err);
    //   });
  },
};

export { LoginHttpServices };
