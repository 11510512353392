// import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./404.scss";
import { Container } from "@mui/material";
import { translate } from "services/translate";

// interface Location {
//   state: {
//     from: {
//       pathname: string;
//     };
//   };
// }
function Page404() {
  //   let navigate = useNavigate();
  //   const [form, setForm] = useState({
  //     email: { email: "", isValid: false, isTuched: false },
  //   });

  //   const location = useLocation() as Location;

  // navigate("/reset-password");

  return (
    <div className="cp-page-not-found-container">
      <Container
        className="cp-page-not-found-content cp-page-not-found-flex"
        component="section"
      >
        <div className="cp-page-not-found-content-container">
          <img src={window.location.origin + "/images/Logo.png"} alt="" />
          <h1 className="cp-page-not-found-content-text">
            {translate("pageNotFound")}
          </h1>
        </div>

        <div className="flex-item cp-page-not-found-options">
          <Link className="forget-pass-link" to="/login" color="#130F26">
            {translate("backToLogin")}
          </Link>
        </div>

        <div className="cp-cp-page-not-found-btn"></div>
        <span className="tentamus-copyright">
          © Tentamus {new Date().getFullYear()}
        </span>
      </Container>

      <section className="cp-page-not-found-flex cp-page-not-found-image">
        <img
          src={window.location.origin + "/images/login-img.png"}
          alt=""
        ></img>
      </section>
    </div>
  );
}

export default Page404;
