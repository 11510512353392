// import React from "react";
import "./CPAccessDenied.scss";
import { translate } from "services/translate";

const CPAccessDenied = () => {
  return (
    <div className="cp-access-denied-error">
      <img src={window.location.origin + "/images/Logo.png"} alt="" />
      <h1 className="cp-page-not-found-content-text">
        {translate("accessDenied")}
      </h1>
    </div>
  );
};

export default CPAccessDenied;
