const config = {
  getBaseUrl: function (): string {
    let url: string = process.env.REACT_APP_CUSTOMER_URL_ADDRESS || "";
    console.log("ENVIRONMENT ", process.env, process.env.CUSTOMER_URL_ADDRESS);
    // if (process.env.NODE_ENV === "production") {
    //   if (process.env.CUSTOMER_PORTAL_BUILD_FOR === "admin") {
    //     url = "https://portalbackend.tentamus.com";
    //   } else {
    //     // url = "https://portalbackend.tentamus.com";
    //     url = "http://172.17.7.95:8080";
    //   }
    // }

    // if (process.env.NODE_ENV === "development") {
    //   console.log("ENVIRONMENT ", process.env);
    //   if (process.env.CUSTOMER_PORTAL_BUILD_FOR === "admin") {
    //     url = "http://65.109.201.11:8080";
    //   } else {
    //     //url = "http://65.109.201.11:8080";
    //     url = "http://172.17.7.95:8080";
    //   }
    // }

    return url;
  },
};

export default config;
