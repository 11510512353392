import { httpServices } from "services/http";

const SupportAccountServices = {
  timer: null as any,
  user: null as any,
  userList: null as any,
  getUserList: async function (params = null) {
    if (localStorage.getItem("userList") !== null) {
      return JSON.parse(localStorage.getItem("userList") || "[]");
    } else {
      return httpServices
        .getMethod("support-account/users", params)
        .then((res: any) => {
          console.log("Support ", res);
          const users = res.data.result.map((user: any) => {
            return {
              label: user.name,
              value: user.id,
            };
          });
          localStorage.setItem("userList", JSON.stringify(users));
          return users;
        });
    }
  },
  getUserID: function (force = false): string | null {
    // clearTimeout(this.timer);
    // this.timer = setTimeout(async () => {
    return localStorage.getItem("userID") || null;
    // }, 20);
  },
  setUser: function (userID: string) {
    this.user = userID;
    localStorage.setItem("userID", userID);
    window.location.reload();
  },
};

export default SupportAccountServices;
