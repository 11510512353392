import React from "react";
import "./OutlinedButton.scss";
import LoadingButton from "@mui/lab/LoadingButton";

function OutlinedButton(props: any) {
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const handleClick = function () {
    props.onClick();
  };

  React.useEffect(() => {
    props.isLoading ? setLoading(true) : setLoading(false);
    props.disabled ? setDisabled(true) : setDisabled(false);
  }, [props]);

  return (
    <LoadingButton
      className={"ct-button-outlined " + props.className}
      onClick={handleClick}
      endIcon={props.endIcon ? props.endIcon : ""}
      startIcon={props.startIcon ? props.startIcon : ""}
      loading={loading}
      disabled={disabled}
      loadingPosition={props.endIcon ? "end" : undefined}
      variant="outlined"
      sx={props.sx}
      color={props.color ? props.color : ""}
    >
      {props.label}
    </LoadingButton>
  );
}

export default OutlinedButton;
