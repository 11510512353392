import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./SignUp.scss";

import { Box, Container, Link } from "@mui/material";

//Components
import InputText from "../../components/shared/input-text/InputText";

import InputPassword from "../../components/shared/input-password/InputPassword";
import DefaultButton from "../../components/shared/default-button/DefaultButton";

// Services
import { LoginHttpServices } from "../../services/login";
import { translate } from "services/translate";
import CPToast from "components/shared/cp-toast/CPToast";

function SignUp() {
  let navigate = useNavigate();
  const location = useLocation() as any;
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log("sign Up Search Params", searchParams.get("token"));
  }, []);

  const [form, setForm] = useState({
    token: {
      token: "",
      isValid: false,
      isTuched: false,
    },
    password: {
      password: "",
      isValid: false,
      isTuched: false,
    },
    repeatPassword: {
      repeatPassword: "",
      isValid: false,
      isTuched: false,
    },
  });
  const [formIsValid, setFormIsValid] = React.useState(false);
  const [passwordMatch, setPasswordMatch] = React.useState(true);

  const [formIsSubmitted, setFormIsSubmitted] = React.useState(false);

  const [toast, setToast] = React.useState({
    open: false,
    type: "success",
    message: "success",
  });

  // Submit Form and get tokenS
  const submit = async function () {
    // First remove previous failed login messages
    //   then check if login button is touched without any entry
    //setAuthIsValid(false);
    if (
      !form.token.isTuched ||
      !form.password.isTuched ||
      !form.repeatPassword.isTuched
    ) {
      let newForm = { ...form };
      newForm.token.isTuched = true;
      newForm.password.isTuched = true;
      setForm(newForm);
      return;
    }

    // stop if form inputs are not valid
    if (!formIsValid) return;

    // Start showing loading and disable button
    setFormIsSubmitted(true);

    await LoginHttpServices.resetPassword({
      token: form.token.token,
      newPassword: form.password.password,
    }).then((res: any) => {
      console.log("REs", res);
      if (res.data?.hasError) {
        setToast({
          ...{
            open: true,
            type: "error",
            message: res.data?.message || "Failed",
          },
        });
        setFormIsSubmitted(false);
      } else {
        setToast({
          ...{
            open: true,
            type: "success",
            message: res.data?.message || "Password Changed",
          },
        });
        setFormIsSubmitted(false);
        setTimeout(() => {
          navigate(location.state?.from?.pathname || "/login");
        }, 3000);
      }
    });
  };

  React.useEffect(() => {
    // First check if inputs are filled at least 3 character
    if (
      (form.token.isTuched && !form.token.isValid) ||
      (form.password.isTuched && !form.password.isValid) ||
      (form.repeatPassword.isTuched && !form.repeatPassword.isValid)
    ) {
      setFormIsValid(false);
    } else {
      //If filled, then check passwords are equal
      if (form.password.password !== form.repeatPassword.repeatPassword) {
        setPasswordMatch(false);
        setFormIsValid(false);
      } else {
        setPasswordMatch(true);
        setFormIsValid(true);
      }
    }
  }, [form]);
  return (
    <div className="cp-reset-password-container">
      <Container
        className="cp-reset-password-content cp-reset-password-flex"
        component="section"
      >
        <div className="welcome-container">
          <img src="images/Logo.png" alt="" />
          <h1 className="welcome-text">{translate("Reset Password")}</h1>
          <p className="welcome-desc">
            {translate(
              "Please Enter your Reset Password Code and New Password"
            )}
          </p>
        </div>
        <Box
          className="cp-reset-password-form"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={submit}
        >
          <InputText
            className="flex-item password-input"
            required={true}
            label={translate("Reset Password Code")}
            id="token"
            value={form.token}
            getValue={(name: any) => setForm({ ...form, token: name })}
          />
          <InputPassword
            className="flex-item password-input"
            required={true}
            label="Password"
            id="password"
            autocomplete="cp-reset-password-password"
            value={form.password}
            getValue={(name: any) => setForm({ ...form, password: name })}
            onKeyPress={(e: any) => {
              if (e.key === "Enter" && formIsValid) {
                submit();
              }
            }}
          />

          <InputPassword
            className="flex-item password-input"
            required={true}
            label="Repeat Password"
            id="repeatPassword"
            autocomplete="cp-reset-password-password"
            value={form.repeatPassword}
            getValue={(name: any) => setForm({ ...form, repeatPassword: name })}
          />

          <div className="flex-item cp-reset-password-options">
            <Link
              className="forget-pass-link"
              href=""
              underline="none"
              color="#130F26"
              onClick={() => {
                navigate(location.state?.from?.pathname || "/login");
              }}
            >
              {translate("Back to Login")}
            </Link>
          </div>

          <div className="cp-cp-reset-password-btn">
            <DefaultButton
              label={translate("Set Password")}
              onClick={submit}
              disabled={!formIsValid}
              isLoading={formIsSubmitted}
            ></DefaultButton>
            {!passwordMatch && (
              <p className="cp-auth-failed">
                {translate("Passwords must match")}
              </p>
            )}
          </div>
        </Box>
        <span className="tentamus-copyright">
          © Tentamus {new Date().getFullYear()}
        </span>
      </Container>

      <section className="cp-reset-password-flex cp-reset-password-image">
        <img src="images/login-img.png" alt=""></img>
      </section>

      <CPToast
        open={toast.open}
        type={toast.type}
        message={toast.message}
        getValue={(value: any) =>
          setToast({
            ...{
              open: false,
              type: "",
              message: "",
            },
          })
        }
      />
    </div>
  );
}

export default SignUp;
