import React from "react";
import { Card, Typography } from "@mui/material";
import "./CPCard.scss";

function CPCard(props: any) {
  return (
    <Card
      sx={props.sx}
      className={"cp-card " + props.className}
      style={props.style || {}}
    >
      {(props.title || props.icon) && (
        <div className="cp-card-header">
          <Typography className="cp-card-title" component="h2">
            {props.icon && (
              <span className="cp-card-title-icon">{props.icon}</span>
            )}
            {props.title}
            {props.afterTitle || ""}
          </Typography>
          <div className="cp-card-right-end-item">
            {props.rightEndItem || ""}
          </div>
        </div>
      )}
      {props.children ? props.children : ""}
    </Card>
  );
}

export default CPCard;
