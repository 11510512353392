import moment from "moment";

const languages: any = {
  en: require("./i18n/en.json"),
  de: require("./i18n/de.json"),
  fr: require("./i18n/fr.json"),
};
function language(lg: any = "en"): any {
  lg = JSON.parse(localStorage.getItem("profile") || "{}")?.language || "en";
  if (!JSON.parse(localStorage.getItem("profile") || "{}")?.language) {
    lg = navigator.language.split("-")[0] || "en";
    if (lg !== "en" && lg !== "de" && lg !== "fr") lg = "en";
  }

  moment.locale(lg);

  const localeData = {
    language: lg,
    data: languages[lg],
  };
  return localeData;
}

function translate(text: string | number) {
  let tempArray = JSON.parse(localStorage.getItem("lnJson") || "[]");
  if (tempArray.indexOf(text) < 0) {
    tempArray.push(text);
    localStorage.setItem("lnJson", JSON.stringify(tempArray));
  }
  return language().data[text] || text;
}

function apiTranslate(main: string, translates?: Array<any>) {
  let languageData = language();
  if (!main) {
    return " ";
  }

  if (!translates || translates.length < 1) {
    return main;
  } else {
    let translated = " ";
    translates.map((item: any) => {
      if (item.language === languageData.language) {
        translated = item.name || item.value;
      }
    });
    if (translated === " ") {
      translated = main;
    }
    return translated;
  }
}

export { translate, apiTranslate };
