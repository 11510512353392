interface CheckAuthorityProps {
  roles?: {
    id: number;
    name: string;
    description: string;
  }[];
  page?: string | string[];
}

function useAuthority() {
  const checkAuthority = ({ roles, page }: CheckAuthorityProps) => {
    const result = roles?.some((role, id) => {
      if (Array.isArray(page)) {
        return page.some((p) => {
          return role?.name === p;
        });
      } else {
        return role?.name === page;
      }
    });
    return result;
  };

  return {
    checkAuthority,
  };
}

export default useAuthority;
