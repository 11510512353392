import React from "react";
import "./DefaultButton.scss";
import LoadingButton from "@mui/lab/LoadingButton";

function DefaultButton(props: any) {
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const handleClick = function () {
    props.onClick();
  };

  React.useEffect(() => {
    props.isLoading ? setLoading(true) : setLoading(false);
    props.disabled ? setDisabled(true) : setDisabled(false);
  }, [props]);

  return (
    <LoadingButton
      className={"ct-button " + props.className}
      onClick={handleClick}
      endIcon={props.endIcon ? props.endIcon : ""}
      startIcon={props.startIcon ? props.startIcon : ""}
      loading={loading}
      disabled={disabled}
      //   {...(props.endIcon ? "loadingPosition='end'" : null)}
      loadingPosition={props.endIcon ? "end" : undefined}
      variant="outlined"
      //variant={props.variant ? props.variant : "contained"}
      sx={props.sx}
      color={props.color || "error"}
    >
      {props?.label}
    </LoadingButton>
  );
}

export default DefaultButton;
