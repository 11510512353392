import React, { Dispatch, SetStateAction } from "react";

import Modal from "@mui/material/Modal";
import "./CPLogoutModal.scss";
import { Typography } from "@mui/material";
import CPCard from "../cp-card/CPCard";
import OutlinedButton from "../outlined-button/OutlinedButton";

import { ReactComponent as LogoutIcon } from "assets/images/svg/logout.svg";
import { ReactComponent as CloseIcon } from "assets/icons/x.svg";

import { useNavigate } from "react-router-dom";
import { translate } from "services/translate";
import DefaultButton from "../default-button/DefaultButton";
import GlobalProfileServices from "services/profile";

type CPLogoutModalProps = {
  openLogout: boolean;
  setOpenLogout: Dispatch<SetStateAction<boolean>>;
};

function CPLogoutModal({ setOpenLogout, openLogout }: CPLogoutModalProps) {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenLogout(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    GlobalProfileServices.profile = null;
    navigate("../login", { replace: true });
  };

  return (
    <Modal
      open={openLogout}
      onClose={handleClose}
      className="cp-logout-modal"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <CPCard>
          <span className="cp-modal-logout-close-icon" onClick={handleClose}>
            <CloseIcon />
          </span>
          <span className="cp-modal-logout-icon">
            <LogoutIcon />
          </span>
          <Typography className="cp-logout-modal-title">
            {translate("logoutLabel")}
          </Typography>
          <Typography className="cp-logout-modal-desc">
            {translate("logoutDesc")}
          </Typography>
          <div className="cp-logout-modal-btn">
            <OutlinedButton
              className="cp-logout-modal-cancel-btn"
              label={translate("cancelLabel")}
              onClick={handleClose}
              color="success"
            ></OutlinedButton>
            <DefaultButton
              label={translate("yesLogout")}
              className="cp-logout-modal-out-btn"
              onClick={handleLogout}
            ></DefaultButton>
          </div>
        </CPCard>
      </div>
    </Modal>
  );
}

export default CPLogoutModal;
