import React from "react";
import { TextField, InputLabel, FormControl } from "@mui/material";
import isEmail from "validator/lib/isEmail";

import "./InputEmail.scss";

function InputEmail(props: any) {
  const [state, setState] = React.useState({
    [props.id]: "",
    isValid: false,
    isTuched: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    let newState = {
      [props.id]: val,
      isValid: props.required ? (isEmail(val) ? true : false) : true,
      isTuched: true,
    };
    setState(newState);
    props.getValue(newState);
  };

  React.useEffect(() => {
    setState({ ...props.value });
  }, [props]);

  //   React.useEffect(() => {
  //     setState(props.value);
  //   }, [props]);

  // const [isValid, setIsValid] = React.useState(true);

  // const [value, setValue] = React.useState(props.value);
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  // 	var value = event.target.value;
  // 	setValue(value);
  // 	props.getValue(value);
  // 	if (value.length > 3) setIsValid(isEmail(value));
  // };

  return (
    <FormControl className={"input " + props.className}>
      <InputLabel htmlFor="component-helper">
        {props.label + (props.required ? "*" : " ") || "name"}
      </InputLabel>
      <TextField
        required={props.required ? true : false}
        error={state.isTuched && !state.isValid}
        placeholder={props.placeholder || props.label}
        id={props.id ? props.id + "cp" : "input-secret-id"}
        variant="outlined"
        value={state[props.id]}
        autoComplete="off"
        disabled={props.disabled ? true : false}
        inputProps={{
          autoComplete: "cp",
        }}
        helperText={
          state.isTuched && !state.isValid ? "Email is not correct" : ""
        }
        onChange={handleChange}
        onKeyUp={props.onKeyUp || null}
      />
    </FormControl>
  );
}

export default InputEmail;
